import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "How to write and enable custom Fail2ban filters",
  "description": "Custom filters are an effective way to catch known attacks and apply the appropriate\nrestriction to the attacker's IP address.\n",
  "meta": {
    "title": "How to write custom Fail2ban rules",
    "description": "Custom filters are an effective way to automate targeted ban on known attacks"
  },
  "tags": ["Fail2ban"],
  "publishedAt": "2016-09-01T13:00:00",
  "published": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><em parentName="p">{`Previously, I've shared `}<a parentName="em" {...{
          "href": "/en/blog/catch-and-block-wordpress-xml-rpc-attack"
        }}>{`how I caught and blocked a brute force attack on Wordpress xmlrpc.php`}</a>{`.
I'll use this attack as an example to explain how to write a custom filter and
use it to automatically block an attacker.`}</em></p>
    <hr></hr>
    <h2>{`A short introduction`}</h2>
    <p>{`Fail2ban is a software that watches log files and detect patterns in text. When
a pattern is detected, it creates a firewall rule to block the attacker's IP address.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.digitalocean.com/community/tutorials/how-fail2ban-works-to-protect-services-on-a-linux-server"
      }}>{`Read this article`}</a>{`
to learn more about how Fail2ban works, I'll focus only on how to write patterns
and enable detection of those patterns on Ubuntu Server 14.04`}<sup>{` `}<a id="note1-link" href="#note1">{`1`}</a></sup>{`.`}</p>
    <p>{`The first thing is to install Fail2ban:`}</p>
    <pre><code parentName="pre" {...{}}>{`sudo apt-get update
sudo apt-get install -y fail2ban
`}</code></pre>
    <hr></hr>
    <h2>{`Look for patterns in log files`}</h2>
    <p>{`In my case, a Wordpress brute force attack on the `}<inlineCode parentName="p">{`xmlrpc.php`}</inlineCode>{` file, it can be
detected in the Apache's access log file:`}</p>
    <pre><code parentName="pre" {...{}}>{`$ sudo cat /var/log/apache2/access.log | grep xmlrpc.php
191.96.249.54 - [26/Aug/2016:21:59:56 -0400] "POST /xmlrpc.php HTTP/1.0" 403 471 "-" "Mozilla/4.0 (compatible: MSIE 7.0; Windows NT 6.0)"
`}</code></pre>
    <p>{`Indicators I use to identify the attack are:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`POST`}</inlineCode>{` request - `}<em parentName="li">{`contains the payload, credentials`}</em></li>
      <li parentName="ul">{`sent to `}<inlineCode parentName="li">{`/xmlrpc.php`}</inlineCode>{` - `}<em parentName="li">{`targeted vulnerability`}</em></li>
      <li parentName="ul">{`returning a `}<inlineCode parentName="li">{`403`}</inlineCode>{` status code - `}<em parentName="li">{`failed attempt to login`}</em></li>
    </ul>
    <p>{`I use `}<a parentName="p" {...{
        "href": "http://regexr.com/"
      }}>{`RegExr`}</a>{` to make sure my regular expression is correct:`}</p>
    <img alt="Regexr interface" caption="https://regexr.com" frame="box" src="fail2ban-regexr.png" width="90" />
    <p>{`The pattern I come up with is `}<inlineCode parentName="p">{`^\\S+ .+POST \\/xmlrpc\\.php HTTP.+ 403`}</inlineCode></p>
    <p>{`Then I replace the `}<inlineCode parentName="p">{`\\S+`}</inlineCode>{` part, which stands for `}<em parentName="p">{`"a sequence of any
non whitespace character",`}</em>{` with the Fail2ban `}<inlineCode parentName="p">{`<HOST>`}</inlineCode>{` token. The Fail2ban's `}<inlineCode parentName="p">{`failregex`}</inlineCode>{` now looks like this:`}</p>
    <pre><code parentName="pre" {...{}}>{`^<HOST> .+POST \\/xmlrpc\\.php HTTP.+ 403
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`Different operating systems or configurations might prefix log lines
with additional information and requires to add `}<a parentName="p" {...{
          "href": "https://fail2ban.readthedocs.io/en/latest/filters.html#syslog"
        }}><inlineCode parentName="a">{`%(__prefix_line)s`}</inlineCode></a>{`
after`}{` `}<inlineCode parentName="p">{`^`}</inlineCode></p>
    </blockquote>
    <hr></hr>
    <h2>{`Create a new filter that catch the pattern`}</h2>
    <p>{`I create a new `}<inlineCode parentName="p">{`xmlrpc-bruteforce.conf`}</inlineCode>{` file in the `}<inlineCode parentName="p">{`/etc/fail2ban/filter.d`}</inlineCode>{` directory:`}</p>
    <pre><code parentName="pre" {...{}}>{`$ sudo nano /etc/fail2ban/filter.d/xmlrpc-bruteforce.conf

# Fail2Ban filter for WordPress bruteforce through xmlrpc.php file
#


[INCLUDES]

before = common.conf

[Definition]

failregex = ^<HOST> .+POST \\/xmlrpc\\.php HTTP.+ 403

ignoreregex =

`}</code></pre>
    <p>{`Including the `}<inlineCode parentName="p">{`common.conf`}</inlineCode>{` file allows the use of generic replacement patterns
such as `}<inlineCode parentName="p">{`%(__prefix_line)`}</inlineCode>{`.`}</p>
    <hr></hr>
    <h2>{`Test the filter with Apache's log file`}</h2>
    <p>{`Thankfully, Fail2ban comes with a testing tool that makes testing straightforward:`}</p>
    <pre><code parentName="pre" {...{}}>{`$ fail2ban-regex /var/log/apache2/access.log /etc/fail2ban/filter.d/xmlrpc-bruteforce.conf

Running tests
=============


Use failregex file : /etc/fail2ban/filter.d/xmlrpc-bruteforce.conf
Use log file : /var/log/apache2/access.log

# Results

...
Lines: 1720 lines, 0 ignored, 456 matched, 1264 missed
`}</code></pre>
    <p>{`The test successfully shows that the pattern is found in 456 of 1720 lines
contained in the `}<inlineCode parentName="p">{`access.log`}</inlineCode>{` file.`}</p>
    <p>{`To confirm, I run the test on a part of the file only, where I know how many occurences
should be found.`}</p>
    <hr></hr>
    <h2>{`Define a jail to apply the filter`}</h2>
    <p>{`Once the filter is created, I add a new `}<em parentName="p">{`jail`}</em>{` by creating the
`}<inlineCode parentName="p">{`/etc/fail2ban/jail.d/xmlrpc-bruteforce.conf`}</inlineCode>{` file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ sudo nano /etc/fail2ban/jail.d/xmlrpc-bruteforce.conf

[xmlrpc-bruteforce]

enabled = true                         # rule is enable
filter = xmlrpc-bruteforce             # given this filter
logpath = /var/log/apache2/access.log  # watch this file
maxretry = 1                           # and on first match
banaction = iptables-allports          # apply this ban
bantime = 604800                       # for 1 week
protocol = all                         # for any protocol
`}</code></pre>
    <p><inlineCode parentName="p">{`maxretry`}</inlineCode>{` defines how many times a given IP address can match the filter before
being sent to jail. The above jail is pretty strict given that there's no
legitimate use of the file for this Wordpress installation.`}</p>
    <hr></hr>
    <h2>{`Activate the new jail`}</h2>
    <p>{`To enable jails, Fail2ban has to reload:`}</p>
    <pre><code parentName="pre" {...{}}>{`$ sudo fail2ban-client reload
2015-04-18 16:12:08,321 fail2ban.jail   : INFO   Jail 'xmlrpc-bruteforce' started
`}</code></pre>
    <h3>{`Fail2ban jail in action`}</h3>
    <p>{`Fail2ban has created a new firewall rule on the first attempt (ESTABLISHED) and I can see dropped
traffic (SYN_SENT) with `}<inlineCode parentName="p">{`tcptrack`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{}}>{`$ tcptrack -i venet0 port 80
...
191.96.249.54:55646 169.125.128.112:80  SYN_SENT    4s   0 B/s
191.96.249.54:41928 169.125.128.112:80  SYN_SENT    8s   0 B/s
191.96.249.54:46015 169.125.128.112:80  SYN_SENT    5s   0 B/s
191.96.249.54:59913 169.125.128.112:80  SYN_SENT    30s  0 B/s
191.96.249.54:60861 169.125.128.112:80  SYN_SENT    23s  0 B/s
191.96.249.54:54418 169.125.128.112:80  SYN_SENT    5s   0 B/s
191.96.249.54:36830 169.125.128.112:80  SYN_SENT    18s  0 B/s
191.96.249.54:49217 169.125.128.112:80  ESTABLISHED 1s   901 B/s
...
`}</code></pre>
    <note id={1}>Setup: Ubuntu 14.04.5 LTS, Fail2Ban v0.8.11, tcptrack v1.4.2</note>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      